<script>
import {mapGetters} from "vuex";
import {GameStatus} from "../../gameCommon";
import * as gameSock from "../../gameSock";

export default {
  name: "WinnerResult",
  data(){
    return {
      show: false,
      rank: 0,
      prize: 0,
      showLock: false,
    }
  },
  computed:{
    ...mapGetters('game', ['roomInfo', 'myInfo', 'tournamentInfo']),
    isShow(){
      return this.show && !this.showLock;
    },
  },
  watch: {
    tournamentInfo:{
      immediate: true,
      handler(state) {
        const myInfo = this.myInfo;
        const tournamentInfo = state;

        if( this.showLock === false && myInfo.sn && tournamentInfo?.ranks ){
          const rankData = tournamentInfo.ranks.find(item => item.playerSN === myInfo.sn);
          const prizeData = tournamentInfo.prizes.find(item => item.rank === rankData?.rank)

          //랭킹,상금 정보가 존재하고, 랭킹정보가 활성상태이며, 방상태값이 게임종료일경우 결과 보여주기
          if( rankData && prizeData && this.roomInfo.gameStatus === GameStatus.End ){
            this.rank = rankData.rank;
            this.prize = prizeData.amount;
            this.show = true;
          }
        }
      }
    }
  },
  methods: {
    async displayNone(){
      this.show = false;
      this.showLock = true;
      await gameSock.poker_out(this.$socket, Number(this.$route.query.roomCode));
      await this.$router.replace({name: 'lobby'});
    },
  }
}
</script>

<template>
  <div>
    <!-- 토너먼트1위 -->
    <transition name="bounce">
      <div v-if="isShow">
        <div  class="area_tournament_1st">
          <div class="user_1st">
            <div class="wrap_profile">
              <div class="area_laurel"></div>
              <div class="profile">
                <div class="centered">
                  <img src="@/assets/v2/img/avatar/avatar02.jpg" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="txt_1st">
            <div class="txt01">{{ rank }}위</div>
            <div class="txt02">{{ myInfo.name }}</div>
            <div class="num01"><span class="ico_coin"></span><span class="align-middle">{{ this.prize | formatCash }}</span></div>
            <div class="txt03">CONGRATULATION</div>
          </div>
          <div class="text-center mt-5">
            <button type="button" class="btn_close" @click.prevent.stop="displayNone">SKIP</button>
          </div>
        </div>
      </div>

    </transition>
  </div>
</template>

<style scoped lang="scss">
.bounce-enter-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  z-index: 9999;
  animation: bounce-in .5s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>